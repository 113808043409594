import './layout.css'
import React from 'react'
import Layout from '../components/layout'

class HowItWorks extends React.Component {
	render() {
		return (
			<Layout>
				<h2 className="how-it-works-title has-text-centered title is-3">How it Works</h2>
				<div className="how-it-works-content">
					<p className="intro">To use our matching platform, you will first need to purchase a ticket to one of our events. Tickets can be purchased from the Events page by clicking on the name of the event you would like to attend.</p>
					<h3>Before the Event</h3>
					<ul>
						<li>On the Events page, find the name and date of the event that you have purchased a ticket for. Click on the ‘Request Access’ button. The button will then change to ‘Pending’ until we grant everyone access on the day of the event.</li>
						<li>It’s important that you request access before the event starts to ensure you are included in the matching list.</li>
						<li>If you have used Encounter Match at a previous event, you will still need to click on the request access button for each event that you attend.</li>
					</ul>
					<p className="tip-text"><strong>Tip:</strong> You can add Encounter Match to the home screen of your smartphone for easy access. When you are on the Encounter Match site, simply select 'Add to Home Screen' in your browser.</p>
					<div className="space"></div>
					<h3>After the Event</h3>
					<p className="small-space">We recommend selecting your matches as soon as possible after the event, ideally once you leave the venue or when you arrive home.</p>
					<ul>
						<li>After the event has ended you will be able to select your matches on the events page by clicking on the ’Start Matching’ button. If you do not see this button please contact us right away on <a href="tel:0444555857" className="has-text-dark">0444 555 857</a>.</li>
						<li>Once you click on the ‘Start Matching’ button a list of the guests of the opposite sex will be displayed with their photo and first name.</li>
						<li>Select the heart button next to anyone you would like to match with.</li>
						<li>You can click on someone’s photo to enlarge it and zoom in to see them better. This helps to make sure you’re selecting the right person.</li>
						<li>Once you have finished selecting all your matches for the event, click the ‘Done Matching’ button. Once you have pushed this button you cannot go back and change the people you have selected.</li>
					</ul>
					<div className="space"></div>
					<h3>Your Match Results</h3>
					<ul>
						<li>To see if you’ve received any mutual matches, click on the ‘Result’ button on the events page or go to the menu and select the My Matches page.</li>
						<li>As everyone will be selecting their matches at different times, you may not see any matches right away. Your final match results will be shown 48hrs after the end time of the event on the My Matches page.</li>
					</ul>
					<div className="space"></div>
					<h3>Need some help?</h3>
					<p className="small-space">Email us at <a href="mailto:help@encounterdating.com.au">help@encounterdating.com.au</a></p>
					<p className="small-space">Text or call us on <a href="tel:0444555857" className="has-text-dark">0444 555 857</a></p>
				</div>
			</Layout>
		)
	}
}

export default HowItWorks;